import React, {Component} from 'react';
import {graphql, Link, StaticQuery} from "gatsby";
import styled from "styled-components";
import {media} from "utils/Media"
import snowFlakeTop from "../../images/snow-flake-menu-top.svg"
import snowFlakeBottom from "../../images/snow-flake-menu-bottom.svg"

const Nav = styled.nav`
    transition: opacity .2s ease-in-out;
    bottom: 0;
    left: 0;
    z-index: 500;
    max-width: initial;
    transform: translateX(-100%);
    opacity: 0;
    width: 100%;
    background: white;
    display: unset;
    position: fixed;
    top: 50px;

    @media ${media.sm} {
        position: absolute;
        top: 63px;
    }
    
    @media ${media.md} {
        width: 450px;
    }

    ul {
        position: sticky;
        top: 70px;
        z-index: 502;
        margin-top: 5rem;
        max-height: calc(100vh - 70px);
        margin-bottom: 0;
        text-align: left;
        overflow-y: auto;
        padding-bottom: 4rem;
        padding-left: 0;
        margin-right: -17px;
        width: 100%;
        height: 100%;

        @media ${media.md} {
            margin-right: 0;
            width: 100%;
        }

        &.navToggled {
            width: calc(100% + 17px);
        }

        &::before {
            position: absolute;
            top: 0;
            left: 0;
            content: "";
            z-index: 503;
            width: 100%;
            height: 100%;
            background: url("${snowFlakeTop}") no-repeat right top;
            background-size: 200px;
        }

         &::after {
            position: absolute;
            top: 50px;
            left: 0;
            content: "";
            z-index: 503;
            width: 100%;
            height: calc(100% - 70px);
            background: url("${snowFlakeBottom}") no-repeat -20% bottom;
            background-size: 200px;
        }

        & > li {
            display: block;
            margin: 0.5rem 0;
            position: relative;
            width: calc(100% - 17px);
            padding-left: 15px;
            z-index: 504;

            @media ${media.md} {
                width: 100%;
            } 
            ul li {
                margin: 0 auto;
            }

            &.bg {
                padding-left: 0;
                div {
                    background: linear-gradient(180deg, rgba(129, 224, 255, 0.5) 0%, rgba(129, 224, 255, 0) 100%);
                }
            }

            &.bigger span {
                font-size: 1.25rem;
                text-transform: uppercase;
                font-weight: bold;
                line-height: 150%;
                color: #000;

                @media ${media.sm} {
                    font-size: 1.75rem;
                }
            }

            .link {
                color: #000;
                font-style: normal;
                font-size: 1rem;
                line-height: 150%;
                font-weight: normal;

                @media ${media.sm} {
                    font-size: 1.25rem;
                }

                &.active, &:hover {
                    color: #3400A3;
                    text-decoration: none;
                }
            }
            
            .subNav {
                font-size: .875rem;
                
                @media ${media.sm} {
                    font-size: 1.125rem;
                }
            }

            button {
                background: none;
                border: none;
                padding: 0;
            }
        }
    }

    .submenu {
        display: none;
        margin: 0 auto;
        padding: 0;
        .link {
            font-size: 0.875rem;
            font-weight: bold;

            @media ${media.sm} {
                font-size: 1.125rem;
            }
        }

        &.visible {
            display: block;
        }
    }

    &.enrichment {
        ul {
            margin-top: 0;
            height: calc(100vh - 70px) !important;
            background: white;
            position: relative !important;
        }
    }

    &.active {
        transform: translateX(0);
        opacity: 1;
    }
`

const Query = (props) => (
    <StaticQuery
        query={graphql`
            query {
               allStoryEnrichmentJson {
                    edges {
                        node {
                            slug
                            name
                            pages {
                                slug
                                name
                            }
                        }
                    }
                }
               allTeachingResourcesJson {
                    edges {
                        node {
                            slug
                            name
                        }
                    }
               }
            }
		`}
        render={data => (
            <Navigation
                {...props}
                data={data}/>
        )}
    />
)

class Navigation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            subMenuVisible: {
                adaptation: false,
                design: false,
                inspiration: false
            }
        }
    }

    toggleSubMenu = (name) => {
        let subMenuVisible = this.state.subMenuVisible

        subMenuVisible[name] = !subMenuVisible[name]

        this.setState({
            subMenuVisible
        })
    }

    render() {
        return (
            <Nav id="navbarSupportedContent"
                 className={`nav ${this.props.navToggled ? 'active' : ''} ${this.props.enrichment ? 'enrichment' : ''} ${this.props.collapsed ? 'collapsed' : ''}`}>
                <ul className={`nav__btns ${this.props.navToggled ? 'navToggled' : ''}`}>
                    <li>
                        <Link to="/" className="link font-weight-bold" activeClassName="active">
                            Home
                        </Link>
                    </li>
                    <li>
                        <div className="link font-weight-bold color--darkBlue color--navy">
                             Beyond the Story:
                        </div>
                    </li>
                    <li>
                        <button className="link subNav font-weight-bold" onClick={() => this.toggleSubMenu("inspiration")}>
                            Inspiration
                        </button>
                        <ul className={`submenu ${this.state.subMenuVisible.inspiration ? "visible" : "hidden"}`}>
                            <li>
                                <Link to="/beyond-the-story/inspiration/nordic-influences/" activeClassName="active" className="link font-weight-normal">Nordic Influences</Link><br/>
                                <Link to="/beyond-the-story/inspiration/the-snow-queen/" activeClassName="active" className="link font-weight-normal">The Snow Queen</Link><br/>
                                <Link to="/beyond-the-story/inspiration/animated-feature-film/" activeClassName="active" className="link font-weight-normal">Animated Feature Film</Link>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <button className="link subNav font-weight-bold" onClick={() => this.toggleSubMenu("design")}>
                            Design
                        </button>
                        <ul className={`submenu ${this.state.subMenuVisible.design ? "visible" : "hidden"}`}>
                            <li>
                                <Link to="/beyond-the-story/design/costumes/" activeClassName="active" className="link font-weight-normal">Costumes</Link><br/>
                                <Link to="/beyond-the-story/design/puppets/" activeClassName="active" className="link font-weight-normal">Puppets</Link><br/>
                                <Link to="/beyond-the-story/design/scenic/" activeClassName="active" className="link font-weight-normal">Scenic</Link>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <button className="link subNav font-weight-bold" onClick={() => this.toggleSubMenu("adaptation")}>
                            Adaptation
                        </button>
                        <ul className={`submenu ${this.state.subMenuVisible.adaptation ? "visible" : "hidden"}`}>
                            <li>
                                <Link to="/beyond-the-story/adaptation/characters/" activeClassName="active" className="link font-weight-normal">Characters</Link><br/>
                                <Link to="/beyond-the-story/adaptation/story/" activeClassName="active" className="link font-weight-normal">Story</Link><br/>
                                <Link to="/beyond-the-story/adaptation/music/" activeClassName="active" className="link font-weight-normal">Music</Link><br/>
                                <Link to="/beyond-the-story/adaptation/screen-to-stage/" activeClassName="active" className="link font-weight-normal">Screen to Stage</Link>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <div className="link font-weight-bold color--darkBlue color--navy">
                            Teaching Resources:
                        </div>
                    </li>
                    <li>
                        <Link to="/teaching-resources/lesson-plans/" className="link subNav font-weight-bold" activeClassName="active">
                            Lesson Plans
                        </Link>
                    </li>
                    <li>
                        <Link to="/teaching-resources/wellbeing-resources/" className="link subNav font-weight-bold" activeClassName="active">
                            Wellbeing Resources
                        </Link>
                    </li>
                    <li>
                        <Link to="/teaching-resources/workshops/" className="link subNav font-weight-bold" activeClassName="active">
                            Workshops
                        </Link>
                    </li>
                </ul>
            </Nav>
        );
    }
}

export default Query;
