import React, { Component } from "react"
import PropTypes from "prop-types"
import { ThemeProvider } from "styled-components"
import 'scss/main.scss'
import SEO from "components/Seo/Seo"
import "scss/bootstrap.scss"
import GlobalStyles from "utils/GlobalStyles";
import theme from "utils/Theme"
import Header from "../Header/Header"
import Footer from "components/Footer/Footer"
import NewDisneyFooter from "components/Footer/NewDisneyFooter"
import { handleLinkClick } from "js/handleLinkClick"

import { NewHeader } from "../NewHeader"

class Layout extends Component {
	componentDidMount() {
		this.dcfTracking()
		this.interstitialCheck()
		// const hash = window.location.hash.split("?");
		//
		// if (hash[0] && hash[0].includes('#show-cookie-manager')) {
		// 	setTimeout(() => {
		// 		window.DisneyGDPR.Core.openPreferencesDialog()
		// 	}, 200)
		// }
		window.addEventListener('keydown', this.handleFirstTab);
		window.scrollTo(0, 0)

		const header = document.getElementById("header");
        const siteWrap = document.getElementById("site-wrap");
        let headerHeight = header.clientHeight;
        siteWrap.style.paddingTop = `${headerHeight +  1}px`;
        window.addEventListener("resize", () => {
          headerHeight = header.clientHeight;
          siteWrap.style.paddingTop = `${headerHeight + 1}px`;
        });
	}

	dcfTracking() {
		if (typeof window !== 'undefined' && typeof window.__dataLayer !== 'undefined') {
			window.__dataLayer.trackPage()
			console.log('Tracking is set')
		}
	}

	interstitialCheck() {

		// Do not fire following code if already on interstitial
		if (window.location.pathname !== '/interstitial/') {

			// Grab all links
			const links = document.getElementById('site-wrap').getElementsByTagName('a');

			// Loop links
			Array.prototype.forEach.call(links, link => {

				// Skip links that have a class with no-interstitial-check
				if (!link.classList.contains('no-interstitial-check') && link.hasAttribute('href')) {

					// Add event listener to each
					link.addEventListener("click", function (event) {
						handleLinkClick(event, link);
					});

				}
			})
		}
	}

	handleFirstTab(e) {
		if (e.keyCode === 9) { // the "I am a keyboard user" key
			document.body.classList.add('user-is-tabbing');
			window.removeEventListener('keydown', this.handleFirstTab);
		}
	}

	render() {
		return(
			<ThemeProvider theme={theme}>
				   {!this.props.interstitial &&
            // <Header
            //   categorySlug={this.props.categorySlug}
            //   categoryTitle={this.props.category}
            //   currentPage={this.props.currentPage}
            //   enrichmentHeader={this.props.enrichmentHeader}
            //   subMenuHidden={this.props.subMenuHidden}
            //   pageName={this.props.pageName}
            // />
            <NewHeader />
            }
				<div id="site-wrap">
					<SEO />
					<GlobalStyles />
					{/* {!this.props.interstitial &&
					<Header
						categorySlug={this.props.categorySlug}
						categoryTitle={this.props.category}
						currentPage={this.props.currentPage}
						enrichmentHeader={this.props.enrichmentHeader}
						subMenuHidden={this.props.subMenuHidden}
						pageName={this.props.pageName}
						mainCategorySlug={this.props.mainCategorySlug}
					/>} */}
					<main id="main" className={`${this.props.enrichmentHeader && 'enrichmentHeader'} ${this.props.interstitial && 'interstitial'} ${this.props.subMenuHidden && 'subMenuHidden'}`}>{this.props.children}</main>
					{/* {!this.props.interstitial && <Footer />} */}
					{!this.props.interstitial && <NewDisneyFooter />}
					
				</div>
			</ThemeProvider>
		)
	}
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout