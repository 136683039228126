import React, { useEffect, useRef, useState } from "react";
import { Link } from "gatsby";

import DropDown from "./DropDown";
import DesktopDropDown from "./DesktopDropDown";

const Navigation = ({ setOpen, open, videoOpen, setVideoOpen }) => {
  const handleClick = () => {
    if (setOpen) {
      setOpen(false);
    }
  };

  const beyondTheStory = [
    {
      title: "Inspiration",
      links: [
        {
          url: `/beyond-the-story/inspiration/nordic-influences/`,
          text: `Nordic Influences`,
        },
        {
          url: `/beyond-the-story/inspiration/the-snow-queen/`,
          text: `The snow queen`,
        },
        {
          url: `/beyond-the-story/inspiration/animated-feature-film/`,
          text: `Animated feature film`,
        },
      ],
    },
    {
      title: "Design",
      links: [
        {
          url: `/beyond-the-story/design/costumes/`,
          text: `Costumes`,
        },
        {
          url: `/beyond-the-story/design/puppets/`,
          text: `Puppets`,
        },
        {
          url: `/beyond-the-story/design/scenic/`,
          text: `Scenic`,
        },
      ],
    },
    {
      title: "Adaptation",
      links: [
        {
          url: `/beyond-the-story/adaptation/characters/`,
          text: `Characters`,
        },
        {
          url: `/beyond-the-story/adaptation/story/`,
          text: `Story`,
        },
        {
          url: `/beyond-the-story/adaptation/music/`,
          text: `Music`,
        },
        {
          url: `/beyond-the-story/adaptation/screen-to-stage/`,
          text: `Screen To Stage`,
        },
      ],
    },
  ];
  const teachingResources = [
    {
      url: `/teaching-resources/lesson-plans/`,
      text: `Lesson Plans`,
    },
    {
      url: `/teaching-resources/wellbeing-resources/`,
      text: `Wellbeing Resources`,
    },
    {
      url: `/teaching-resources/workshops/`,
      text: `Workshops`,
    },
  ];

  return (
    <ul className="nav">
      <DesktopDropDown
        text="BEYOND THE STORY"
        subLinks={beyondTheStory}
        width="450"
      />
      <DropDown text="BEYOND THE STORY" subLinks={beyondTheStory} />
      <DesktopDropDown
        text="TEACHING RESOURCES"
        subLinks={teachingResources}
        width="190"
      />
      <DropDown text="TEACHING RESOURCES" subLinks={teachingResources} />
    </ul>
  );
};
export default Navigation;

