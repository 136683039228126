import { navigate } from 'gatsby'

export function handleLinkClick(event, link) {
    event.preventDefault();

    const element = event.currentTarget;
    const href = element.getAttribute('href')
    const siteUrl = 'frozeneducation.co.uk'
    const currentUrl = window.location.pathname

    // If href is a for the site itself exit
    if (href.indexOf(siteUrl) > 0 || (href.substring(0, 1) === '/' && href.substring(0, 2) !== '//')) {
        // Proceed with link as normal
        navigate(href);
    } else {

        // Redirect to interstitial with correct state
        navigate("/interstitial/", {
            state: {
                href: href,
                previousUrl: currentUrl
            }
        });
    }
}