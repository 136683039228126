import React, { useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import { gsap } from 'gsap'

import Navigation from './Navigation'

const StyledMenu = styled.div`
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0;
  width: 100%;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  border-top: solid 1px #fff;
  ${props =>
    props.open &&
    css`
      border-top: solid 1px #000;
    `}
  .menu {
    margin: 0 auto;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        position: relative;
        width: 100%;
        background: #fff;
        z-index:99999;
        border-bottom: 1px solid #eaeaea;
        a {
          padding: 0.85rem 2.5rem;
          display: block;
          font-weight: 900;
          color: #000;
          @media (min-width: 768px) {
            padding: 0.85rem 2.5rem 0.85rem 3.5rem;
          }
        }
      }
    }
  }
  @media (min-width: 992px) {
    display: none;
  }
`

export default function MobileMenu({ open, setOpen, videoOpen, setVideoOpen }) {
  const target = useRef()
  const tl = useRef()
  useEffect(() => {
    const ctx = gsap.context(() => {
      tl.current = gsap
        .timeline()
        .to(target.current, {
          opacity: 1,
          visibility: 'visible',
          top: '72px',
        })
        .to(
          '.bottom',
          {
            rotate: '-45deg',
          },
          '<'
        )
    }, target)
  }, [])
  useEffect(() => {
    if (!open) {
      tl.current.reverse()
    } else {
      tl.current.play()
    }
  }, [open])
  return (
    <StyledMenu open={open} ref={target}>
      <div className="menu">
        <Navigation
          open={open}
          setOpen={setOpen}
          videoOpen={videoOpen}
          setVideoOpen={setVideoOpen}
        />
      </div>
    </StyledMenu>
  )
}
