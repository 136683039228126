import React, {Component} from 'react';
import {Modal, ModalBody} from "reactstrap"
import styled from "styled-components"
import TrailerIframe from 'components/TrailerIframe/TrailerIframe'

const VModal = styled(Modal)`
    max-width: 1400px;
    padding: 0;
    border: 0;

    .modal-content {
        border: 0;
        position: relative;
    }

    .modal-body {
        padding: 0;
        border: 0;
    }
`

const ButtonClose = styled.button`
    position: absolute;
    background-color: transparent;
    border: 0;
    z-index: 10;
    right: 1rem;
    top: 1rem;
    width: 30px;
    height: 30px;

    @media (min-width: 992px) {
        width: 50px;
        height: 50px;
    }

    &:before,
    &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: #fff;
        transform: translate(-50%, -50%) rotate(-45deg);
    }

    &:after {
        transform: translate(-50%, -50%) rotate(45deg);
    }
`

class VideoModal extends Component {
    render() {
        return (
            <VModal isOpen={this.props.modalOpen} toggle={this.props.toggleModal} centered={true}>
                <ButtonClose onClick={this.props.toggleModal}>
                    <span className="sr-only">Close</span>
                </ButtonClose>
                <ModalBody>
                    <TrailerIframe src={this.props.src} className="embed-responsive embed-responsive-16by9"/>
                </ModalBody>
            </VModal>
        );
    }
}

export default VideoModal;