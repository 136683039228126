import React, {Component} from "react"
import styled from "styled-components"
import {media} from "utils/Media"
import {Link} from 'gatsby'
import 'moment-timezone';
import Navigation from "./Navigation";
import iconDownload from "../../images/icon-download.svg";
import iconHouse from "../../images/icon-house.png";
import EducationMenu from "../Education/EducationMenu";

const scrollToElement = require('scroll-to-element');

const Relative = styled.div`
    position: relative;
    padding: 0 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media ${media.sm} {
        padding: 0 15px;
    }
`

const HeaderContainer = styled.div`
    background: #f2fefd;
    display: flex;      
    align-items: center;
    position: absolute;
    top: 65px;
    width: 100%;
    max-width: 1920px;
    padding: 0;
    transition: background .2s ease-in-out;
    z-index: 501;
    min-height: 50px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);

    @media ${media.md} {
        min-height: 62px;
    }
    
     @media ${media.lg} {
        min-height: 70px;
    }

    &.collapsed {
        top: 0;
        position: fixed;
    }

`

const NavToggle = styled.button`
    position: relative;
    z-index: 502;
    height: 30px;
    width: 30px;
    margin-left: 0;
    transition: all .2s linear;
    cursor: pointer;
    padding: 0;
    border: none;
    background: none;

    @media ${media.sm} {
      height: 40px;
      width: 40px;
    }

    @media ${media.lg} {
    }

    span {
		width: 25px;
        height: 3px;
		display: block;
		background: linear-gradient(135deg, #81E0FF 0%, #2B66E3 100%);
		position: absolute;
		transition: all .2s ease-in-out;
		left: 6px;
		z-index: 503;

		&:nth-child(1) {
			top: 7px;
		}

		&:nth-child(2) {
			top: 50%;
			transform: translateY(-50%);
		}

		&:nth-child(3) {
			bottom: 7px;
		}

		@media ${media.sm} {
		    left: 8px;
		    width: 24px;
		    height: 3px;

		    &:nth-child(1) {
                top: 11px;
            }

            &:nth-child(2) {
                top: 50%;
                transform: translateY(-50%);
            }

            &:nth-child(3) {
                bottom: 11px;
            }
		}
	}

	&.active {
		span {
			&:nth-child(1) {
				top: 50%;
				transform: translateY(-50%) rotate(-45deg);
			}

			&:nth-child(2) {
				opacity: 0;
			}

			&:nth-child(3) {
				top: 50%;
				bottom: auto;
				transform: translateY(-50%) rotate(45deg);
			}
		}
	}

`

const EduWrapper = styled.div`
    align-items: center;
    flex: 1 0 0;
    display: flex;
`

const EduMenu = styled.div`
    display: flex;
    align-items: center;
    margin-left: 10px;
    padding-left: 5px;
    border-left: 1px solid #81E0FF;
    
    &.hidden {  
        display: none;
        
    }

    a {
        margin-right: 10px;
        margin-left: 5px;
    }
`

class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            navToggled: false,
            collapsed: false,
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, true)
        window.addEventListener('resize', this.handleScroll, true)
        document.body.classList.remove('nav-open');

    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll, true)
        window.removeEventListener('resize', this.handleScroll, true)
        document.body.classList.remove('nav-open');
    }

    handleScroll = () => {
        // let scrollPosition = document.documentElement.scrollTop
        let scrollPosition = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop)
        let collapsed = scrollPosition < 63 ? false : true
        this.setState({
            collapsed
        })
    }

    toggleNav = () => {
        if (!this.state.navToggled === false) {
            document.body.classList.remove('nav-open');
        } else {
            document.body.classList.add('nav-open');
        }
        this.setState({
            navToggled: !this.state.navToggled
        })
    }

    scrollDown = (id, offset = 0) => {
        scrollToElement(id, {
            duration: 300,
            offset: offset
        })

        this.setState({
            navToggled: false
        }, () => {
            if (typeof window !== 'undefined') {
                let linkData = {
                    pageName: `${id}`,
                    siteName: "frozenmusical",
                    country: "uk",
                    region: "emea",
                    actionName: "nav-click",
                    actionValue1: `${id}`
                }
                // console.log(linkData)
                // window.utag.link(linkData)
            }
        })
    }

    onClick = (e) => {
        if (typeof window !== 'undefined') {
            let linkData = {
                pageName: `${this.props.pageName}`,
                siteName: "frozenmusical",
                country: "uk",
                region: "emea",
                actionName: "button-click",
                actionValue1: "Sign Up For Tickets"
            }
            // window.utag.link(linkData)

            window.did.launchNewsletters('frozen')
                // this API returns a Promise object (thenable)
                // the first function passed to then() is success(data)
                .then(function (data) {
                    console.log(data);
                })
                // the second function is an error handler (optional)
                .fail(function (error) {
                    console.log(error);
                });

        }
    }

    render() {
        const {navToggled, collapsed} = this.state

        return (
            <React.Fragment>
                <HeaderContainer className={`${navToggled ? 'active' : ''} ${collapsed ? 'collapsed' : ''}`}>
                    <Relative>
                        <NavToggle
                            className={`${navToggled ? 'active' : ''} ${collapsed ? 'collapsed' : ''}`}
                            onClick={this.toggleNav}
                            aria-expanded="false"
                            aria-controls="navbarSupportedContent"
                            type="button"
                            aria-label="Toggle navigation"
                        >

                            <span/>
                            <span/>
                            <span/>
                        </NavToggle>
                        <React.Fragment>
                            <EduWrapper>
                                <EduMenu className={`${this.props.subMenuHidden && "hidden"}`}>
                                    <Link to="/">
                                        <img src={iconHouse} alt="" title=""/>
                                    </Link>
                                    {(this.props.mainCategorySlug === 'beyond-the-story' || this.props.mainCategorySlug === 'teaching-resources') &&
                                    <EducationMenu
                                        categorySlug={this.props.categorySlug}
                                        categoryTitle={this.props.categoryTitle}
                                        currentPage={this.props.currentPage}
                                        mainCategorySlug={this.props.mainCategorySlug}
                                        pageName={this.props.pageName}
                                    />
                                    }
                                    {/*<EducationMobileMenu*/}
                                    {/*    categorySlug={this.props.categorySlug}*/}
                                    {/*    categoryTitle={this.props.categoryTitle}*/}
                                    {/*    currentPage={this.props.currentPage}*/}
                                    {/*    pageName={this.props.pageName}*/}
                                    {/*    isHidden={this.state.isOpen}*/}
                                    {/*/>*/}
                                </EduMenu>
                            </EduWrapper>
                            {/*<a href="/pdf/FROZEN_LessonPlans_FullSet.pdf" download target="_blank"*/}
                            {/*   className="no-interstitial-check btn btn--new d-flex mx-auto text-uppercase text-white downloadAllBtn">*/}
                            {/*        <span className="d-none d-lg-inline-block">*/}
                            {/*        Download all lesson plans*/}
                            {/*        </span>*/}
                            {/*    <span className="d-inline-block d-lg-none">*/}
                            {/*            Download all*/}
                            {/*        </span>*/}
                            {/*    <img src={iconDownload} alt="" title=""/>*/}
                            {/*</a>*/}
                        </React.Fragment>
                    </Relative>
                </HeaderContainer>
                <Navigation
                    collapsed={collapsed}
                    navToggled={navToggled}
                />
            </React.Fragment>
        )
    }
}

export default Header
