import { createGlobalStyle, withTheme } from "styled-components";
import fontFiles from "./Fonts";
import { media } from "utils/Media"

const GlobalStyles = createGlobalStyle`

    @font-face {
        font-family: "Proxima Nova W05 Light";
        src:
            url(${fontFiles.ProximaNovaW05LightWOFF}) format("woff");
        font-display: swap;
    }

    @font-face {
        font-family: "Proxima Nova W05 Regular";
        src:
            url(${fontFiles.ProximaNovaW05RegularWOFF}) format("woff");
        font-display: swap;
    }

    @font-face {
        font-family: "Proxima Nova W05 Medium";
        src:
            url(${fontFiles.ProximaNovaW05MediumWOFF}) format("woff");
        font-display: swap;
    }

    @font-face {
        font-family: "Proxima Nova W05 Bold";
        src:
            url(${fontFiles.ProximaNovaW05BoldWOFF}) format("woff");
        font-display: swap;
    }

    @font-face {
        font-family: "noto_sansbold";
        src:
            url(${fontFiles.noto_sansboldWOFF}) format("woff");
        font-display: swap;
    }

    @font-face {
        font-family: "noto_sansregular";
        src:
            url(${fontFiles.noto_sansregularWOFF}) format("woff");
        font-display: swap;
    }

    @font-face {
        font-family:"Turquoise W05 Inline";
        src:
            url(${fontFiles.TurquoiseW05InlineWOFF}) format("woff");
        font-display: swap;
    }

    body {
        font-family: ${props => props.theme.font.family.body};
        font-size: ${props => props.theme.font.size.base};
        font-weight: normal;
        line-height: ${props => props.theme.font.lineHeight.base};
        color: ${props => props.theme.colors.black};
        -webkit-font-smoothing: antialiased;
        overflow-x: hidden;
        
        &.nav-open {
            overflow-y: hidden;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: ${props => props.theme.font.family.bold};
    font-weight: bold;
        font-weight: normal;
    }

    h1, h2, .h1 {
        font-size: ${props => props.theme.font.h2.xs.size};
        line-height: ${props => props.theme.font.h2.xs.lineHeight};

        @media ${media.sm} {
            font-size: ${props => props.theme.font.h2.sm.size};
            line-height: ${props => props.theme.font.h2.sm.lineHeight};
        }

        @media ${media.lg} {
            font-size: ${props => props.theme.font.h2.size};
            line-height: ${props => props.theme.font.h2.lineHeight};
        }

        &.font--bigger {
            font-size: ${props => props.theme.font.h2.bigger.xs.size};
            line-height: ${props => props.theme.font.h2.bigger.xs.lineHeight};

            @media ${media.sm} {
                font-size: ${props => props.theme.font.h2.bigger.sm.size};
                line-height: ${props => props.theme.font.h2.bigger.sm.lineHeight};
            }

            @media ${media.lg} {
                font-size: ${props => props.theme.font.h2.bigger.size};
                line-height: ${props => props.theme.font.h2.bigger.lineHeight};
            }
        }

        &.font--smaller {
            font-size: ${props => props.theme.font.h2.smaller.xs.size};
            line-height: ${props => props.theme.font.h2.smaller.xs.lineHeight};

            @media ${media.sm} {
                font-size: ${props => props.theme.font.h2.smaller.sm.size};
                line-height: ${props => props.theme.font.h2.smaller.sm.lineHeight};
            }

            @media ${media.lg} {
                font-size: ${props => props.theme.font.h2.smaller.size};
                line-height: ${props => props.theme.font.h2.smaller.lineHeight};
            }
        }
    }
    h3 {
        font-size: ${props => props.theme.font.h3.sm.size};
        line-height: ${props => props.theme.font.h3.sm.lineHeight};

        @media ${media.lg} {
            font-size: ${props => props.theme.font.h3.size};
            line-height: ${props => props.theme.font.h3.lineHeight};
        }
    }
    h4 {
        font-size: ${props => props.theme.font.h4.sm.size};
        line-height: ${props => props.theme.font.h4.sm.lineHeight};

        @media ${media.md} {
            font-size: ${props => props.theme.font.h4.md.size};
            line-height: ${props => props.theme.font.h4.md.lineHeight};
        }

        @media ${media.xl} {
            font-size: ${props => props.theme.font.h4.size};
            line-height: ${props => props.theme.font.h4.lineHeight};
        }
    }
    h5 {
        font-size: ${props => props.theme.font.h5.sm.size};
        line-height: ${props => props.theme.font.h5.sm.lineHeight};

        @media ${media.lg} {
            font-size: ${props => props.theme.font.h5.size};
            line-height: ${props => props.theme.font.h5.lineHeight};
        }

        &.font--bigger {
            font-size: ${props => props.theme.font.h5.bigger.sm.size};
            line-height: ${props => props.theme.font.h5.bigger.sm.lineHeight};

            @media ${media.lg} {
                font-size: ${props => props.theme.font.h5.bigger.size};
                line-height: ${props => props.theme.font.h5.bigger.lineHeight};
            }
        }
    }
    h6 {
        font-size: ${props => props.theme.font.h6.sm.size};
        line-height: ${props => props.theme.font.h6.sm.lineHeight};

        @media ${media.lg} {
            font-size: ${props => props.theme.font.h6.size};
            line-height: ${props => props.theme.font.h6.lineHeight};
        }
    }

    p {
        font-size: ${props => props.theme.font.p.sm.size};
        line-height: ${props => props.theme.font.p.sm.lineHeight};

        @media ${media.lg} {
            font-size: ${props => props.theme.font.p.size};
            line-height: ${props => props.theme.font.p.lineHeight};
        }

        &.smaller {
            font-size: ${props => props.theme.font.p.smaller.sm.size};
            line-height: ${props => props.theme.font.p.smaller.sm.lineHeight};

            @media ${media.lg} {
                font-size: ${props => props.theme.font.p.smaller.size};
                line-height: ${props => props.theme.font.p.smaller.lineHeight};
            }
        }
    }

    a {
        color: ${props => props.theme.colors.navy};
        font-weight: ${props => props.theme.font.family.bold};
        &:hover {
            color: ${props => props.theme.colors.navy};
        }
    }

    ul {
        padding-left: 20px;
    }

    img {
        max-width: 100%;
    }

    .video-wrapper {
        position: relative;
        //padding-bottom: 75%; /* 4:3 aspect ratio */
        padding-bottom: 56.25%; /* 16:9 aspect ratio */
        padding-top: 0; // height of controls
        height: 0;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
        }

        .posterImage {
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            cursor: pointer;

            &.playing {
                display: none;
            }
        }
    }

    .play-icon {
        position: absolute;
        z-index: 2;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 70px;

        @media ${media.lg} {
            width: 120px;
        }
    }

    .modal-backdrop.show {
        background: #003199;
        opacity: 0.8;
    }

    //.modal-dialog {
    //    max-width: calc(100% - 30px);
    //    margin: 1.75rem auto;
    //}

    /* #main {
        margin-top: 50px;
        
        @media ${media.md} { 
            margin-top: 63px;
        }
        
        @media ${media.lg} { 
            margin-top: 70px;
        }

        &.interstitial {
            margin-top: 0;
        }
    } */
`;

export default withTheme(GlobalStyles);
