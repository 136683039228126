import ProximaNovaW05LightWOFF2 from "./fonts/ProximaNovaW05-Light.woff2";
import ProximaNovaW05LightWOFF from "./fonts/ProximaNovaW05-Light.woff";

import ProximaNovaW05RegularWOFF2 from "./fonts/ProximaNovaW05-Regular.woff2";
import ProximaNovaW05RegularWOFF from "./fonts/ProximaNovaW05-Regular.woff";

import ProximaNovaW05MediumWOFF2 from "./fonts/ProximaNovaW05-Medium.woff2";
import ProximaNovaW05MediumWOFF from "./fonts/ProximaNovaW05-Medium.woff";

import ProximaNovaW05BoldWOFF2 from "./fonts/ProximaNovaW05-Bold.woff2";
import ProximaNovaW05BoldWOFF from "./fonts/ProximaNovaW05-Bold.woff";

import noto_sansboldWOFF2 from "./fonts/NotoSans-Bold.woff2";
import noto_sansboldWOFF from "./fonts/NotoSans-Bold.woff";

import noto_sansregularWOFF2 from "./fonts/NotoSans.woff2";
import noto_sansregularWOFF from "./fonts/NotoSans.woff";

import TurquoiseW05InlineWOFF2 from "./fonts/TurquoiseW05-Inline.woff2";
import TurquoiseW05InlineWOFF from "./fonts/TurquoiseW05-Inline.woff";

export default {
    ProximaNovaW05LightWOFF2,
    ProximaNovaW05LightWOFF,
    ProximaNovaW05RegularWOFF2,
    ProximaNovaW05RegularWOFF,
    ProximaNovaW05MediumWOFF2,
    ProximaNovaW05MediumWOFF,

    ProximaNovaW05BoldWOFF2,
    ProximaNovaW05BoldWOFF,

    noto_sansboldWOFF2,
    noto_sansboldWOFF,

    noto_sansregularWOFF2,
    noto_sansregularWOFF,

    TurquoiseW05InlineWOFF2,
    TurquoiseW05InlineWOFF
};