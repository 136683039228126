import React from 'react'
import styled from 'styled-components'

const IframeWrapper = styled.div`
    margin: 0 auto;

    @media (min-width: 992px) {
        /* margin: 2.5% auto;    */
    }

    iframe {
        border: 0;
    }
`

const TrailerIframe = (props) => {
    return (
        <IframeWrapper {...props}>
            <iframe
                src={props.src}
                allowFullScreen={true}
                allow="autoplay *; fullscreen *; encrypted-media *"
                sandbox="allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation"
                frameBorder="0"
                title="FROZEN"
                className="embed-responsive-item"
            />
        </IframeWrapper>
    )
}

export default TrailerIframe