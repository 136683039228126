import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";

import { media } from "utils/Media";

const StyledDesktopDropDown = styled.li`
  display: none;
    @media ${media.lg} {
      &:hover, &:active {
          div {
              opacity: 1;
              visibility: visible;
          }
      }
        display: block;
        > div {
            position: relative;
            span {
                font-weight: 700!important;
                display: block;
            }
        }
        &:nth-child(1) {
            > div {
                margin-left: -5px!important;
            }
    }
    }
`;
const StyledDesktopDropDownLinks = styled.div`
    position: absolute;
    top: 45px;
    transition: all .25s ease-in-out;
    opacity: 0;
    visibility: hidden;
    margin-left: -20px;
  
    > ul {
        width: ${props => props.width}px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        list-style: none;
        margin: 0;
        gap: 1rem;
        padding: 1.25rem 1.5rem ;
        background: rgb(35, 43, 45);
        li {
            a {
                color: #fff;
            }
        }
    }
    .fat-nav {
      width: ${props => props.width}px;
        display: flex;
        justify-content: space-between;
        margin: 0;
        gap: .5rem;
        padding: 1.25rem 1.5rem ;
        background: rgb(35, 43, 45);
        p {
          color: #fff;
          padding: 0 0 .5rem 0;
          margin: 0;
          font-size: 14px;
        }
        ul {
          list-style: none;
          display: flex;
          flex-direction: column;
          gap: 1rem;
          margin: 0;
          padding: 0;
          li a {
            color: #fff;
            font-weight: 400;
          }
        }
    }
    .square {
      width: 15px;
      height: 15px;
      transform: rotate(45deg);
      background: rgb(35, 43, 45);
      position: absolute;
      top: -5px;
      left: calc(20% - 5px);
      content: '';
    }

`

export default function DesktopDropDown({ text, subLinks, width }) {
  return (
    <StyledDesktopDropDown >
      <div className="navigation-link">
      {text}
        {/* <span tabIndex={0} role="button">{text}</span> */}
        <SubLinkDropDown data={subLinks} width={width} />
      </div>
    </StyledDesktopDropDown>
  );
}

const SubLinkDropDown = ({ data, width }) => {
  console.log(data)
  return (
    <StyledDesktopDropDownLinks width={width} tabIndex={0} role="button">
      <div className="square" />
      <>
        {!data[0].title ? (
          <ul>
          {data.map((item, i) => {
            return (
              <li key={i} tabIndex={0} role="button">
                {item.external ? (
                  <a href={item.url} target="_blank">
                    <span className="sr-only">{item.text}</span>
                    {item.text}
                  </a>
                ) : (
                  <Link to={item.url}>
                    <span className="sr-only">{item.text}</span>
                    {item.text}
                  </Link>
                )}
              </li>
            );
          })}
        </ul>
        ) : (
          <div className="fat-nav">
            {data.map((item, i) => {
              const {title, links } = item
              return (
                <div>
                <p>{title}</p>
                <ul>
                  {links.map((link, i) => (
                    <li key={i} tabIndex={0} role="button">
                       <Link to={link.url}>
                    <span className="sr-only">{link.text}</span>
                    {link.text}
                  </Link>
                    </li>
                  ))}
                </ul>
                </div>
              )
            })}
          </div>
          
        )}
      
      </>
    </StyledDesktopDropDownLinks>
  );
};
