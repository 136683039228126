import React, {Component} from 'react';
import {graphql, StaticQuery, Link} from "gatsby";
import {media} from "utils/Media"
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";

const Menu = styled.div`
    width: 100%;
    text-align: center;
    position: relative;
    display: none;
    
    @media ${media.md} {
        display: flex;
    }
    
    ul {
        list-style: none;
        margin: 0;
        padding: 1rem 0 1rem 70px;
        width: 100%;
        transition: max-height 0.2s linear;
        position: relative;
        z-index: 2;
        text-align: left;

        li {
            padding: 5px;
            display: inline-block;
            color: #003199;
            font-weight: bold;
            font-size: 1.125rem;

            a {
                color: #000000;
                font-weight: normal;
                font-size: 1.125rem;
            }

            &:hover {

                a {
                    color: #3400A3;
                    text-decoration: underline;
                }
            }

            &:last-of-type {
                display: none;
            }
        }
    }
    
    .dropdown {
        padding-left: 5px;
        padding-right: 5px;
        border-left: 1px solid #81E0FF;    
        
        button:not(.dropdown-item) {
            background: none;
            border: none;
            text-decoration: none;
            font-weight: normal;
            font-size: 0.875rem;
            
            @media ${media.md} {
                font-size: 1.125rem;
            }
        }
        
        &:last-of-type {
            button:not(.dropdown-item) {
                color: ${props => props.theme.colors.navy};
                font-weight: bold;
                text-decoration: underline;
            }
        }
        
        .dropdown-menu {
            border-radius: 0;
            border: none;
            top: 3px !important;
            background: linear-gradient(180deg, rgba(0,0,0,0.1) 0%, rgba(255,255,255,1) 12%), #ffffff;
            
            @media ${media.md} {
                top: 13px !important;
            }
        }

        &-item {
            padding: 0 !important;
            
            &:hover, &:focus {
                background-color: #7BF6EF;
            }
        }

        a {
            display: block;
            width: 100%;
            height: 100%;
            padding: 0.5rem 1rem;
            margin: 0;
            font-weight: bold;
        }
    }
    
`

const Query = (props) => (
    <StaticQuery
        query={graphql`
            query {
               allStoryEnrichmentJson {
                    edges {
                        node {
                            slug
                            name
                            pages {
                                slug
                                name
                            }
                        }
                    }
                }
               allTeachingResourcesJson {
                    edges {
                        node {
                            slug
                            name
                        }
                    }
               }
            }
		`}
        render={data => (
            <EducationMenu
                {...props}
                data={data}/>
        )}
    />
)

class EducationMenu extends Component {

    constructor(props) {
        super(props);

        this.state = {
            mainCategoryDropdownOpen: false,
            categoryDropdownOpen: false,
            pageDropdownOpen: false
        };
    }

    toggleMainCategoryDropdown = () => {
        this.setState({
            mainCategoryDropdownOpen: !this.state.mainCategoryDropdownOpen
        })
    }

    toggleCategoryDropdown = () => {
        this.setState({
            categoryDropdownOpen: !this.state.categoryDropdownOpen
        })
    }

    togglePageDropdown = () => {
        this.setState({
            pageDropdownOpen: !this.state.pageDropdownOpen
        })
    }

    getCategories = () => {
        let content = [];
        this.props.data.allStoryEnrichmentJson.edges.map(({node}, i) => {
            if (node.slug !== this.props.categorySlug) {
                content.push(
                    <DropdownItem key={i}>
                        <Link to={`/${this.props.mainCategorySlug}/${node.slug}/${node.pages[0].slug}/`}
                              className="">{node.name}</Link>
                    </DropdownItem>
                )
            }
        })
        return content
    }

    getEnrichmentPages = () => {
        let content = [];
        this.props.data.allStoryEnrichmentJson.edges.map(({node}, i) => {
            if (node.slug === this.props.categorySlug) {
                node.pages.map((page, key) => {
                    if (page.slug !== this.props.pageName) {
                        content.push(
                            <DropdownItem key={key}>
                                <Link to={`/${this.props.mainCategorySlug}/${node.slug}/${page.slug}/`}
                                      className="">{page.name}</Link>
                            </DropdownItem>
                        )
                    }
                })
            }
        })
        return content
    }
    getTeachingPages = () => {
        let content = [];
        this.props.data.allTeachingResourcesJson.edges.map(({node}, i) => {
            if (node.slug !== this.props.pageName) {
                content.push(
                    <DropdownItem key={i}>
                        <Link to={`/${this.props.mainCategorySlug}/${node.slug}/`} className="">{node.name}</Link>
                    </DropdownItem>
                )
            }
        })
        return content
    }

    render() {
        return (
            <Menu>
                <Dropdown isOpen={this.state.mainCategoryDropdownOpen}
                          toggle={this.toggleMainCategoryDropdown}>
                    <DropdownToggle caret>
                        {this.props.mainCategorySlug === 'beyond-the-story' ?
                            <span>Beyond the Story</span>
                            :
                            <span>Teaching Resources</span>
                        }
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem>
                            {this.props.mainCategorySlug === 'beyond-the-story' ?
                                <Link to="/teaching-resources/lesson-plans" className="">Teaching
                                    Resources</Link>
                                :
                                <Link to="/beyond-the-story/inspiration/nordic-influences" className="">Beyond the Story</Link>
                            }
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>

                {this.props.mainCategorySlug === 'beyond-the-story' &&
                <Dropdown isOpen={this.state.categoryDropdownOpen}
                          toggle={this.toggleCategoryDropdown}>
                    <DropdownToggle caret>
                        {this.props.categoryTitle}
                    </DropdownToggle>
                    <DropdownMenu>
                        {this.getCategories()}
                    </DropdownMenu>
                </Dropdown>
                }

                <Dropdown isOpen={this.state.pageDropdownOpen}
                          toggle={this.togglePageDropdown}>
                    <DropdownToggle caret>
                        {this.props.currentPage}
                    </DropdownToggle>
                    <DropdownMenu>
                        {this.props.mainCategorySlug === 'beyond-the-story' ?
                            this.getEnrichmentPages()
                            :
                            this.getTeachingPages()
                        }
                    </DropdownMenu>
                </Dropdown>
            </Menu>
        );
    }
}

export default Query;

EducationMenu.propTypes = {
    mainCategorySlug: PropTypes.string,
    categorySlug: PropTypes.string,
    categoryTitle: PropTypes.string,
    currentPage: PropTypes.string,
    pageName: PropTypes.string,
}
