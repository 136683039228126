import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import Hamburger from './Hamburger'
import DisneyLogo from './DisneyLogo'
import MobileMenu from './MobileMenu'
import Navigation from './Navigation'
import VideoModal from "../shared/VideoModal";

import { media } from "utils/Media"


const StyledHeader = styled.header`
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 9999999;
  background: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-bottom: solid 1px #000;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1440px;
    width: 90%;
    margin: 0 auto;
    padding: 1rem 0;
    @media (min-width: 992px) {
      padding: 0;
    }
    &--wrapper {
      @media (min-width: 992px) {
        display: flex;
        align-items: center;
        gap: 30px;
        ul {
          display: flex;
          li {
            font-weight: 700;
            font-size: 14px;
          }
        }
      }
      .logo {
        margin-left: -20px;
        svg {
          width: auto;
          height: 40px;
        }
        @media (min-width: 992px) {
          margin: 0;
          svg {
            height: 41px;
          }
        }
      }
      .navigation {
        display: none;
        @media (min-width: 992px) {
          display: block;
          width: 100%;
          .nav {
            padding: .5rem 0;
            margin: 0;
            > li {
              padding: 1rem 1.5rem;
            }
          }
        }
      }
    }
  }
`
export default function NewHeader() {
  const [open, setOpen] = useState(false)
  useEffect(() => {
    if (open) {
      document.documentElement.classList.add('nav-active')
    } else {
      document.documentElement.classList.remove('nav-active')
    }
  })
  return (
    <>
      <StyledHeader id="header" open={open}>
        <div className="header">
          <Hamburger open={open} setOpen={setOpen} />
          <div className="header--wrapper">
            <div className="logo">
              <a href="/">
                <DisneyLogo />
              </a>
            </div>
            <div className="navigation">
              <Navigation
                open={open}
                setOpen={setOpen}
              />
            </div>
          </div>
          <div className="buttons">
           <div></div>
          </div>
        </div>
      </StyledHeader>
      <MobileMenu
        open={open}
        setOpen={setOpen}
      />
    </>
  )
}



// onClick={(e) => this.toggleVideoModal(e, "")}