import React, { Component } from "react"
import styled from 'styled-components'
import DisneyFooter from "./DisneyFooter"
import seeItSafety from "../../images/See-It-Safely-Logo.png"

const FooterStyled = styled.footer`
    background-color: white;
    width: 100%;
    position: relative;
    z-index: 5;
`

const SocialIcons = styled.div`
    padding-top: 2rem;
    text-align: center;
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
`

const SocialIcon = styled.a`
    margin: 0 1rem;
    text-decoration: none;
    color: #003199;
    font-size: 2rem;

    &:hover {
        text-decoration: none;
        color: black;
    }
`

class Footer extends Component {
    socialMediaClicked = (channel) => {
        if (typeof window !== 'undefined' && typeof window.utag !== 'undefined') {
            // Tealium
            let linkData = {
                siteName: "frozeneducation",
                country: "uk",
                region: "emea",
                actionName: "social",
                actionValue1: channel
            }
            window.utag.link(linkData)
        }
    }

    render() {
        return (
            <FooterStyled className="footer">
                <SocialIcons>
                    <SocialIcon
                        href="https://www.facebook.com/frozenlondon/"
                        className="icon icon-facebook"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={()=>this.socialMediaClicked("facebook")}
                    >
                        <span className="sr-only">facebook</span>
                    </SocialIcon>

                    <SocialIcon
                        href="https://twitter.com/frozenlondon"
                        className="icon icon-twitter"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={()=>this.socialMediaClicked("twitter")}
                    >
                        <span className="sr-only">twitter</span>
                    </SocialIcon>

                    <SocialIcon
                        href="http://instagram.com/frozenlondon"
                        className="icon icon-instagram"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={()=>this.socialMediaClicked("instagram")}
                    >
                        <span className="sr-only">instagram</span>
                    </SocialIcon>

                    {/* <SocialIcon href="https://youtube.com" className="icon icon-youtube" target="_blank" rel="noopener noreferrer"></SocialIcon>
                    <SocialIcon href="https://spotify.com" className="icon icon-spotify" target="_blank" rel="noopener noreferrer"></SocialIcon> */}
                    <SocialIcon
                        href="https://officiallondontheatre.com/see-it-safely/"
                        target="_blank"
                        rel="noreferrer noopener"
                        className="p-0 pb-4"
                    >
                            <img src={seeItSafety} alt="See It Safety" title="See It Safety" className="seeItSafety"/>
                    </SocialIcon>
                </SocialIcons>

                <DisneyFooter />
            </FooterStyled>
        )
    }
}
export default Footer
