import React, { Component } from "react"
import styled from "styled-components"
import { media } from "utils/Media"

const Nav = styled.nav`
    max-width: none;
    font-size: 12px;
    color: #333;
    padding: 12px 64px 0 64px;
    text-align: center;
    font-family: ${props => props.theme.font.family.disneyRegular};

    @media ${media.lg} {
        text-align: left;
    }

    p {
        font-size: 12px;
    }
`

const NavFooterUl = styled.ul`
    padding: 0;
    margin: 0;
    list-style: none;

    li {
        display: inline-block;
        margin-bottom: 1rem;
        
        a {
            color: #333;
            font-size: 12px;
            padding-right: .5rem;
        }
    }
`

const NavCopy = styled.p`
    padding-top: 1rem;
    margin-bottom: 1rem;
    @media ${media.lg} {
        padding-top: 0;
    }
`

class DisneyFooter extends Component {
    callCookie = () => {
        window.DisneyGDPR.Core.openPreferencesDialog()
    }

    render() {
        return (
            <Nav>
                <h3 style={{
                    width: "0",
                    height: "0",
                    margin: "0",
                    padding: "0",
                    color: "transparent",
                }}>About and Legal</h3>
                <NavFooterUl>
                    <li>
                        <a href="https://support.disney.com/hc/en-gb" className="no-interstitial-check">Help</a>
                    </li>
                    <li>
                        <a href="https://disney.co.uk/why-join" className="no-interstitial-check">Register</a>
                    </li>
                    <li>
                        <a href="https://disneytermsofuse.com/english-united-kingdom/" className="no-interstitial-check">Terms of Use</a>
                    </li>
                    <li>
                        <a href="https://privacy.thewaltdisneycompany.com/en/current-privacy-policy/privacy-notice/" className="no-interstitial-check">EU Privacy Rights</a>
                    </li>
                    <li>
                        <a href="https://privacy.thewaltdisneycompany.com/en/current-privacy-policy/" className="no-interstitial-check">Privacy Policy</a>
                    </li>
                    <li>
                        <a href="https://privacy.thewaltdisneycompany.com/en/current-privacy-policy/cookies-policy/" className="no-interstitial-check">Cookie Policy</a>
                    </li>
                    <li>
                        <a href="http://preferences-mgr.truste.com/?pid=disney01&amp;aid=disneyemea01&amp;type=Disneyemea">Interest-based Ads</a>
                    </li>
                    <li>
                        <a href="https://disney.co.uk/taxstrategy" className="no-interstitial-check">Tax Strategy Statement</a>
                    </li>
                    <li>
                        <a href="https://disney.co.uk/internet-safety" className="no-interstitial-check">Internet Safety</a>
                    </li>
                    <li>
                        <a href="https://disney.co.uk/about-us" className="no-interstitial-check">About Us</a>
                    </li>
                    <li>
                        <a href="https://www.thewaltdisneycompany.eu" className="no-interstitial-check">The Walt Disney Company</a>
                    </li>
                    <li>
                        <a target="" onClick={this.callCookie}>Cookie Settings</a>
                    </li>
                </NavFooterUl>
                <NavCopy>© Disney © Disney•Pixar © &amp; ™ Lucasfilm LTD © Marvel. All Rights Reserved </NavCopy>
            </Nav>
        )
    }
}

export default DisneyFooter
