import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { gsap } from 'gsap'
import { Link } from "gatsby";

import Arrow from "./Arrow";
import NestedDropDown from './NestedDropDown';
import { media } from "utils/Media"


const StyledDropDown = styled.li`
  width: 100%;
  background: #fff;
  span {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    padding: 0.85rem 2.5rem;
    font-weight: 900;
    color: #000;
    .icon {
        transform-origin: 25% 50%;
    }
    @media (min-width: 768px) {
            padding: 0.85rem 2.5rem 0.85rem 3.5rem;
          }
  }
  @media ${media.lg} {
    display: none!important;
  }
`;
const StyledSubDropDown = styled.ul`
  width: 100%;
  height: 0;
  z-index: -5;
  opacity: 0;
  visibility: hidden;
  li {  
    background: #F5F5F5;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.10) inset;
    a {
        font-weight: 400!important;
    }
  }
  @media ${media.lg} {
    display: none!important;
  }
`;
export default function DropDown({ url, text, subLinks }) {
  const [open, setOpen] = useState(false);

  const handleKeyDown = (data, event) => {
    console.log(event.key)
    if (event.keyCode === 13 || event.keyCode === 32) {
      if (open) {
        setOpen(false)
      } else {
        setOpen(true)
      }
    }
  }

  const target = useRef()
  const tl = useRef()
  useEffect(() => {
    const ctx = gsap.context(() => {
      tl.current = gsap
        .timeline()
        .to('.nested-icon', {
          rotate: '90deg',
        })
        .to(
          '.sub',
          {
            height: 'auto',
            visibility: 'visible',
            opacity: 1
          },
          '<'
        )
    }, target)
  }, [])
  useEffect(() => {
    if (!open) {
      tl.current.reverse()
    } else {
      tl.current.play()
    }
  }, [open])
  return (
    <StyledDropDown  ref={target} tabIndex={0} role="button"
    onKeyDown={event => handleKeyDown('data', event)}>
      <span onClick={() => setOpen(!open)}>  
        {text}
        <div className="nested-icon">
          <Arrow />
        </div>
      </span>
      
      <SubLinkDropDown data={subLinks} open={open} />
    </StyledDropDown>
  );
}

const SubLinkDropDown = ({ data }) => {
  console.log(data);
  return (
    <StyledSubDropDown className="sub">
      {data.map((item, i) => {
        console.log(item);
        return (
          <>
            {item.title ? (
                <NestedDropDown text={item.title} subLinks={item.links} />
            ) : (
                   <li>
                   {item.external ? ( <a href={item.url} target="_blank" >
                     <span className="sr-only">{item.text}</span>
                     {item.text}
                   </a>) : (<Link to={item.url}>
                     <span className="sr-only">{item.text}</span>
                     {item.text}
                   </Link>)}
                 </li>
            )}
          
          </>
        );
      })}
    </StyledSubDropDown>
  );
};
