import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { gsap } from 'gsap'

const StyledHamburger = styled.div`
  cursor: pointer;
  width: 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  div {
    width: 100%;
    transform-origin: 5.8px;
    height: 2px;
    background-color: #000;
  }
  @media (min-width: 992px) {
    display: none;
  }
`

export default function Hamburger({ open, setOpen }) {
  const handleKeyDown = (data, event) => {
    console.log(event.key)
    if (event.keyCode === 13 || event.keyCode === 32) {
      if (open) {
        setOpen(false)
      } else {
        setOpen(true)
      }
    }
  }
  const target = useRef()
  const tl = useRef()
  useEffect(() => {
    const ctx = gsap.context(() => {
      tl.current = gsap
        .timeline()
        .to('.top', {
          rotate: '45deg',
        })
        .to(
          '.bottom',
          {
            rotate: '-45deg',
          },
          '<'
        )
    }, target)
  }, [])
  useEffect(() => {
    if (!open) {
      tl.current.reverse()
    } else {
      tl.current.play()
    }
  }, [open])
  return (
    <StyledHamburger
      role="button"
      ref={target}
      onClick={() => setOpen(!open)}
      tabIndex={0}
      onKeyDown={event => handleKeyDown('data', event)}
    >
      <div className="top" />
      <div className="bottom" />
    </StyledHamburger>
  )
}
