import { media } from "utils/Media"
// match bootstrap sizes, also add xxl
const sizes = {
    xs: "0px",
    sm: "576px",
    md: "768px",
    lg: "992px",
    xl: "1200px",
    xxl: "1400px",
    disney: "1024px"
};

const fontSizeBase = 1;

const font = {
    family: {
        disneyRegular: `"noto_sansregular", Helvetica, Arial, sans-serif`,
        disneyBold: `"noto_sansbold", Helvetica, Arial, sans-serif`,
        special: `Turquoise W05 Inline`,
        light: `proxima-nova, sans-serif;`,
        base: `proxima-nova, sans-serif;`,
        medium: `proxima-nova, sans-serif;`,
        bold: `proxima-nova, sans-serif;`,
        body: `proxima-nova, sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`
    },
    size: {
        base: `${fontSizeBase * .875}rem`, // 14px
        sm: `${fontSizeBase * .75}rem`, // 12px
        md: `${fontSizeBase}rem`, // 16px
        lg: `${fontSizeBase * 1.25}rem`, // 20px
        xl: `${fontSizeBase * 1.5}rem` // 24px
    },
    lineHeight: {
        sm: 1.1,
        base: 1.4,
        headings: 1.2
    },
    h1: {
        size: `${fontSizeBase * 3.5}rem`,
        lineHeight: `${fontSizeBase * 4.5}rem`,
        sm: {
            size: `${fontSizeBase * 3.5}rem`,
            lineHeight: `${fontSizeBase * 4.5}rem`,
        }
    },
    h2: {
        size: `${fontSizeBase * 3}rem`,
        lineHeight: `${fontSizeBase * 3.75}rem`,
        sm: {
            size: `${fontSizeBase * 2.5}rem`,
            lineHeight: `${fontSizeBase * 3}rem`,
        },
        xs: {
            size: `${fontSizeBase * 1.875}rem`,
            lineHeight: `${fontSizeBase * 2}rem`,
        },
        bigger: {
            size: `${fontSizeBase * 3 * 1.25}rem`,
            lineHeight: `${fontSizeBase * 3.75 * 1.25}rem`,
            sm: {
                size: `${fontSizeBase * 2.5 * 1.25}rem`,
                lineHeight: `${fontSizeBase * 3 * 1.25}rem`,
            },
            xs: {
                size: `${fontSizeBase * 1.875}rem`,
                lineHeight: `${fontSizeBase * 2}rem`,
            }
        },
        smaller: {
            size: `${fontSizeBase * 2}rem`,
            lineHeight: `${fontSizeBase * 2.75}rem`,
            sm: {
                size: `${fontSizeBase * 1.75}rem`,
                lineHeight: `${fontSizeBase * 2.25}rem`,
            },
            xs: {
                size: `${fontSizeBase * 1.5}rem`,
                lineHeight: `${fontSizeBase * 2}rem`,
            },
        }
    },
    h3: {
        size: `${fontSizeBase * 1.875}rem`,
        lineHeight: `${fontSizeBase * 2}rem`,
        sm: {
            size: `${fontSizeBase * 1.25}rem`,
            lineHeight: `${fontSizeBase * 1.5}rem`,
        },
    },
    h4: {
        size: `${fontSizeBase * 1.5}rem`,
        lineHeight: `${fontSizeBase * 1.8125}rem`,
        sm: {
            size: `${fontSizeBase * 1.125}rem`,
            lineHeight: `${fontSizeBase * 1.25}rem`,
        },
        md: {
            size: `${fontSizeBase * 1.25}rem`,
            lineHeight: `${fontSizeBase * 1.5}rem`,
        }
    },
    h5: {
        size: `${fontSizeBase * 1.25}rem`,
        lineHeight: `${fontSizeBase * 1.75}rem`,
        sm: {
            size: `${fontSizeBase}rem`,
            lineHeight: `${fontSizeBase * 1.375}rem`,
        },
        bigger: {
            size: `${fontSizeBase * 1.5}rem`,
            lineHeight: `${fontSizeBase * 2.25}rem`,
            sm: {
                size: `${fontSizeBase * 1.25}rem`,
                lineHeight: `${fontSizeBase * 1.5}rem`,
            },
        }
    },
    h6: {
        size: `${fontSizeBase * 1.25}rem`,
        lineHeight: `${fontSizeBase * 1.75}rem`,
        sm: {
            size: `${fontSizeBase}rem`,
            lineHeight: `${fontSizeBase * 1.375}rem`,
        },
    },
    p: {
        size: `${fontSizeBase * 1.125}rem`,
        lineHeight: `${fontSizeBase * 1.75}rem`,
        sm: {
            size: `${fontSizeBase}rem`,
            lineHeight: `${fontSizeBase * 1.375}rem`,
        },
        smaller: {
            size: `${fontSizeBase}rem`,
            lineHeight: `${fontSizeBase * 1.5}rem`,
            sm: {
                size: `${fontSizeBase * 0.875}rem`,
                lineHeight: `${fontSizeBase * 1.25}rem`,
            }
        }
    }
}

export default {
    sizes,
    font,
    transitionBase: "all .4s ease-in-out",
    colors: {
        white: "#ffffff",
        black: "#000000",
        blackOff: "#222222",
        greyDark: "#4B4B4B",
        blue: "#8AECF5",
        darkBlue: "#003199",
        navy: "#004684",
        grey: "#E5E5E5",
        greyMedium: "#C9C6C6",
        pink: "#E84261",
        purple: "#702283",
        purpleDark: "#42145F",
        yellow: "#FFB624",
        turquoise: "#00ADB9",
        turquoise2: "#63DBE7",
        turquoise3: "#E2FFFF",
        lightTurquoise: "#c0f0f9"
    }
};